import { useEffect, useState } from 'react';
import { Icons } from '../../Assets/Icons';
import { Plus } from '../../Assets/svgs/Plus';
import { RemoveImage } from '../../Assets/svgs/RemoveImage';
import conf from "../../app/config";
import { LocationModal, getCoordinates, getCoordinates2, locationModal } from '../LocationModal/LocationModal';
import './AddPhoto.scss';
import { IncomingMessage } from 'http';
import { BrowserView, MobileView } from 'react-device-detect';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dataUrlToFile } from '../../utils';

export const AddPhoto = (props: any) => {
    const [geolocationModal, setGeolocationModal] = useState<locationModal>({
        open: false,
        msg: "",
    });
    const [rotation, setRotation] = useState<any>()
    const [photo, setPhoto] = useState<any>()
    const [thumbnail, setThumbnail] = useState<any>();
    const ENV = conf('ENV');
    const icons = Icons();
    const imageErrorMsg = () =>
        toast.error(
            <div style={{ color: "white" }}>
                <b style={{ color: "white" }}>Tipo de archivo no soportado.</b>
                <br />
                Utilice imágenes en formato JPG o JPEG
            </div>
        );

    useEffect(() => {
        if (!photo) {
            setThumbnail(null)
            setRotation(true);
            return
        }

        const photoURL = URL.createObjectURL(photo)
        setThumbnail(photoURL)
        setRotation(false)
    }, [photo])

    useEffect(() => {
        if (props.defaultThumbnail) setThumbnail(props.defaultThumbnail)
        if(props.fotoPreservada) {
            const preservedPhoto = async (data: any) => {
                const imagen = await dataUrlToFile(data.imagen, data.id.toString())
                setPhoto(imagen);
            }
            preservedPhoto(props.fotoPreservada)
        }
    }, [])

    const removePhoto = (e: any) => {
        console.log(props.id)
        setPhoto(undefined)
        if (props.onChangeBienes) props.onChangeBienes({})
        if (props.onDelete) props.onDelete(props.id)
        setThumbnail(undefined)
        e.preventDefault()
    }

    const handleChange = async (e: any) => {
        const file = e.target.files[0];
        //PATCH FOR
        //if (!(file.type === 'image/jpg' || file.type === 'image/jpeg')) {
        //   imageErrorMsg()
        //  return;
        // }
        const location: any = await getCoordinates2(setGeolocationModal)
        console.log(location)
        const coordinates = location ? { lat: location.coords.latitude, lng: location.coords.longitude } : { lat: null, lng: null }
        setPhoto(e.target.files[0])
        if (props.onChangeBienes) props.onChangeBienes(e.target.files[0])
        if (props.onChange) props.onChange(e.target.files[0], coordinates, props.data.id, props.data.grupoImagenId)
    }

    const addFillException = (icono: string) => {
        const icons = ['cajaFuerte', 'cuadroElectrico', 'inmueble', 'iconIndustria', 'accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar']
        return icons.includes(icono)
    }

    const renderContent = () => {
        if (!thumbnail) {
            return (
                <label className="add-photo-button">
                    {props.icono ? <div className={`add-photo-button-icon ${addFillException(props.icono) ? 'color-without-fill' : ''}`}>{props.iconos ? props.iconos[props.icono] : icons[props.icono]}</div> : null}
                    <div className="add-photo-button-addicon"><Plus /></div>
                    <MobileView>
                        <input type="file" accept="image/*" onChange={(e: any) => handleChange(e)} />
                    </MobileView>
                    <BrowserView>
                        <input type="file" accept="image/*" onChange={(e: any) => handleChange(e)} />
                    </BrowserView>
                </label>
            )
        } else {
            return (
                <div className='add-photo-thumbnail'>
                    {props.removable ? <div onClick={(e: any) => removePhoto(e)}><RemoveImage /></div> : null}
                    {rotation ?
                        <img src={thumbnail} alt="" className="thumbnail" />
                        : <img src={thumbnail} alt="" />
                    }
                </div>
            )
        }
    }

    return (
        <>
            <form className="add-photo">
                {renderContent()}
                <ToastContainer theme="colored" />
            </form>
            <LocationModal
                geolocationModal={geolocationModal}
                setGeolocationModal={setGeolocationModal}
            />
        </>
    )
}
